<template>
    <div class="content">
        <iframe style="width: 100%;" src="https://www.kuleiman.com/168551/index.html"></iframe> 
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      
      };
    },
    watch: {},
    created() {

    },
    destroyed() {

    },
    methods: {
    
    }
  };
  </script>
  <style scoped>
    .content {
    width: 100%;
    height: 100%;
    position: relative;
    }
  </style>
  
  
  
  